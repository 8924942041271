import type { MessageSchema } from '@/translations';

const messages: MessageSchema = {
  a_few_more_details: 'Encore quelques détails',
  accept_mailinglist_sentence: 'Je m\'inscris aux mailings de Thermen {resort} pour recevoir des nouvelles et des offres personnalisées',
  accept_retreats_mailinglist_sentence: 'Je m\'inscris aux mailings de Retreats pour recevoir des nouvelles et des offres personnalisées',
  accept_terms_sentence: 'Par la présente, je confirme accepter les conditions suivantes : {terms_list}',
  accept_thermenfans_sentence: 'Je m\'inscris au {link} et bénéficie ainsi d\'avantages exclusifs tels que : offres, bons de réduction personnalisés, cadeaux sympas et accès exclusif aux événements',
  add_one: 'Ajouter',
  all_choices: 'Tous les choix',
  an_email_will_be_sent_to_your_email_if_you_press_reset_password_this_email_will_contain_a_link_to_request_a_new_password: 'Un e-mail sera envoyé à votre adresse e-mail si vous appuyez sur réinitialiser le mot de passe. Cet e-mail contiendra un lien pour demander un nouveau mot de passe',
  are_you_sure_you_want_to_reset_your_password: 'Êtes-vous sûr de vouloir réinitialiser votre mot de passe ?',
  arrangement_choice_nr: 'Choix d\'arrangement {n}',
  arrangement_choices: 'Arrangement @:choices',
  arrival_and_departure_time_explanation: 'Pour une visite agréable, nous vous demandons d\'arriver et de partir aux horaires de votre réservation. De cette façon, nous maintenons ensemble un bon équilibre dans le nombre de clients qui effectuent leur check-in et check-out.',
  arrival: 'Arrivée',
  belgium: 'Belgique',
  business_name: 'Nom de l\'entreprise',
  cancellation_policy: 'Conditions d\'annulation',
  choices: 'choix | choix',
  choose_arrangement: 'Faites-en vraiment votre arrangement. Choisissez votre favori parmi les options ci-dessous pour votre journée bien-être.',
  choose_your_arrival_and_departure_day_in_the_calendar: 'Choisissez dans le calendrier votre jour d\'arrivée et de départ',
  choose_your_type_of_entrance: 'Choisissez votre type d\'entrée',
  city: 'Ville',
  close: 'Fermer',
  comments_or_extra_information: 'Commentaires / informations supplémentaires',
  compare_etb_comment: 'Dans cette partie du resort, le port du maillot de bain est obligatoire, alors n\'oubliez pas votre maillot de bain',
  compare_etb_usp_1: 'Accès à deux de nos bains thermaux',
  compare_etb_usp_2: 'Hammam, serre du parc et espaces de repos avec chambre de lavande',
  compare_etb_usp_3: 'Pelouses et parc de peignoirs',
  compare_etb_usp_4: 'Brasserie Bubbels',
  compare_etbs_comment: 'Notre zone de sauna est accessible uniquement sans maillot de bain, à l\'exception de nos journées de maillots de bain (chaque mercredi).',
  compare_etbs_usp_1: 'Accès à nos bains thermaux',
  compare_etbs_usp_2: 'Maison de bain de vapeur, serre du parc et espaces de repos avec chambre de lavande',
  compare_etbs_usp_3: 'Pelouses et parc de peignoirs',
  compare_etbs_usp_4: 'Brasserie Bubbels',
  compare_etbs_usp_5: 'Accès à toutes nos installations de sauna bénéfiques (intérieur & extérieur)',
  compare_etbs_usp_6: 'Espaces de repos',
  compare_etbs_usp_7: 'Lodge Sauna',
  country: 'Pays',
  customer_page_into_sentence: 'Nous avons besoin d\'un peu plus d\'informations de votre part. Juste un peu. Et bien sûr, nous gardons vos données pour nous. Promis.',
  date_of_birth: 'Date de naissance',
  day: 'Jour',
  departure: 'Départ',
  email_address: 'Adresse e-mail',
  estimated_time_of_arrival: 'Heure d\'arrivée prévue',
  estimated_time_of_departure: 'Heure de départ prévue',
  evening: 'Soir',
  first_name: 'Prénom',
  for_who: 'Pour qui ?',
  forgot_your_password_no_problem: 'Mot de passe oublié ? Pas de problème.',
  germany: 'Allemagne',
  go_to_the_website: 'Aller sur le site web',
  hide_all_single_rooms: 'Masquer toutes les chambres simples',
  hotel_room_choice: 'Choix de la chambre d\'hôtel',
  hotel_room_type: 'chambre simple | chambre double',
  hotel_rooms_page_into_sentence: 'Vous venez passer la nuit chez nous ?',
  hotel_rooms: 'Chambres d\'hôtel',
  house_number_addition: 'Ajout',
  house_number: 'Numéro de maison',
  house_rules: 'règlement intérieur',
  how_long: 'Combien de temps ?',
  how_would_you_like_to_book_your_relaxing_moment: 'Comment souhaitez-vous réserver votre moment de détente ?',
  i_have_a_my_thermen_account: 'J\'ai un compte Thermen Resorts',
  i_want_to_create_a_my_thermen_account_tooltip: 'Réservez plus rapidement et ne saisissez plus jamais vos données. Un compte Thermen Resorts vous offre plus. Plus de commodité et plus d\'avantages. Attention ! Votre compte n\'est activé qu\'en cliquant sur le lien dans l\'e-mail de vérification.',
  i_want_to_create_a_my_thermen_account: 'Je souhaite créer un compte Thermen Resorts',
  i_want_to_make_a_reservation_without_an_account: 'Je veux réserver sans compte',
  i_would_like_to_create_an_account: 'Je souhaite créer un compte',
  invoice_comment_placeholder: 'Commentaires/détails (par exemple numéro d\'achat)',
  invoice_information: 'Détails de la facture employeur',
  last_name: 'Nom de famille',
  login: 'Connexion',
  logout: 'Déconnexion',
  lunch_and_diner_page_into_sentence: 'Votre journée bien-être encore plus complète et encore plus agréable ? Découvrez nos séances de versement, rituels, méditations et autres moments de détente agréables... Notre conseil ? Choisissez surtout ce qui vous rend heureux !',
  lunch_and_diner: 'Déjeuner et dîner',
  make_it_your_day: 'Faites-en votre journée !',
  mobile_phone_number: 'Numéro de téléphone portable',
  month: 'Mois',
  more_info: 'Plus d\'info',
  more_information: 'Plus d\'informations',
  morning: 'Matin',
  mr: 'Monsieur',
  ms: 'Madame',
  name_infix: 'Particule',
  next: 'Suivant',
  notice_chosen_date_or_time_is_unavailable: 'La date et/ou l\'heure choisies ne sont pas disponibles !',
  notice_this_hotel_room_is_unavailable: 'Cette chambre n\'est plus disponible pour le jour choisi !',
  number_of_persons: '{n} personne | {n} personnes',
  or_stay_with_us_for_a_good_night_sleep: 'Ou restez-vous dormir agréablement ?',
  other: 'Autre',
  password_confirmation: 'Confirmer le mot de passe',
  password: 'Mot de passe',
  pay: 'Payer',
  per_night: 'par nuit',
  per_person_including_breakfast_starting_from: 'p.p. incluant le petit-déjeuner à partir de',
  per_person_short: 'p.p.',
  persons_count: 'Nombre de personnes',
  persons: 'personne | personnes',
  previous: 'Précédent',
  prices_are_in_euro: 'Les prix sont en € @:per_person_short',
  privacy_policy: 'Politique de confidentialité',
  receive_a_present_on_your_birthday: 'Recevoir un cadeau pour votre anniversaire ?',
  reload: 'Recharger',
  request_your_new_password_here: 'Demandez votre nouveau mot de passe ici.',
  reset_password: 'Réinitialiser le mot de passe',
  reset: 'Réinitialiser',
  rituals_extras_page_into_sentence: 'Votre journée bien-être encore plus complète et encore plus agréable ? Découvrez nos séances de versement, rituels, méditations et autres moments de détente agréables... Notre conseil ? Choisissez surtout ce qui vous rend heureux !',
  rituals_extras: 'Extras pour votre journée au sauna',
  select_all: 'Sélectionner tout',
  select_for_all: 'Sélectionner pour tout le monde',
  select: 'Sélectionner',
  selected: 'Sélectionné',
  send_invoice: 'Mon employeur paie la facture.',
  share_a_room_together_select_one_of_the_available_room_options_below: 'Partagez une chambre ensemble, sélectionnez l\'une des options de chambre disponibles ci-dessous.',
  show_all_single_rooms: 'Afficher toutes les chambres simples',
  something_went_wrong_while_retrieving_your_data: 'Un problème est survenu lors de la récupération de vos données.',
  street: 'Rue',
  swimwear_day_tooltip_sentence: 'Les jours marqués en bleu dans le calendrier sont des jours de maillot de bain. Le port du maillot de bain est obligatoire ces jours-là.',
  swimwear_day: 'Jour de maillot de bain',
  terms_and_conditions: 'Conditions générales',
  terms_item_1: 'Mon heure d\'arrivée et de départ (afin que les éventuels traitements puissent avoir lieu dans la demi-heure suivant l\'heure d\'arrivée indiquée et avant l\'heure de départ indiquée)',
  terms_item_2: 'J\'ai lu le {house_rules}',
  terms_item_3: 'Les {terms_and_conditions}, les {cancellation_policy} et la {privacy_policy}',
  the_difference: 'La différence',
  the_netherlands: 'Pays-Bas',
  there_are_no_selectable_products_available_at_the_selected_time: 'Il n\'y a aucun produit sélectionnable disponible au moment choisi.',
  thermenfans_program: 'Programme Thermenfans',
  total: 'Total',
  treatment_page_into_sentence: 'Votre journée chez nous peut être encore plus complète. Un massage ? Un traitement ? Notre conseil : choisissez ce qui vous rend heureux, c\'est toujours le meilleur choix.',
  unavailable: 'Indisponible',
  view_reservation: 'Voir la réservation',
  we_put_together_the_available_options_for_you: 'Nous rassemblons les options disponibles pour vous...',
  what_is_the_difference: 'Quelle est la différence ?',
  what_would_you_like_to_book: 'Que souhaitez-vous réserver ?',
  when_are_you_coming_to_relax: 'Quand viendrez-vous vous détendre ?',
  when_resetting_previously_entered_data_will_be_lost: 'En réinitialisant, les données saisies précédemment seront perdues.',
  which_type_of_treatment_suits_you: 'Quel type de traitement vous convient ?',
  year: 'Année',
  you_have_number_of_choices: 'Vous avez <strong>{n}</strong> @:choices',
  you_have: 'Vous avez',
  your_experience: 'Voici ce que vous allez vivre :',
  your_first_step_to_great_enjoyment: 'Votre premier pas vers un grand plaisir !',
  zip_code: 'Code postal',
  steps: {
    choices: 'Vos choix',
    complete: 'Terminer',
    customer_details: 'Détails du client',
    reservation: 'Réservation'
  },
};

export default messages;
